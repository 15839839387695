import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';

import {
  LOYALTY_SHARE_ROUTE, LOYALTY_QR_ROUTE,
  LOYALTY_ROUTE, LOYALTY_SEARCH_ROUTE,
} from 'constants/navigation';
import {
  SHARE, REWARD,
  SCAN, SEARCH,
} from 'constants/font-awesome';

import useStyles from '../styles';

const LazyLoyaltyView = loadable(() => import('views/loyalty-card/loyalty'));
const LazyBanner = loadable(() => import('components/banners/in-app-banners/one'));
const Page = loadable(() => import('components/page'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedLoyaltyRedeemNavPage = ({ passId = '', title = '' }) => {
  const styles = useStyles();
  return (
      <Page
        title="Stamp & Reward"
        description="Search for a customers Loyalty card to add stamps or redeem rewards.">
        <LazyBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          {'Add Loyalty or Redeem Reward'}
        </Typography>
        <Typography variant="h5" component="p" className={styles.headingMessage} align="center">
          {'Scan the QR code scanner or alternatively search for the Loyalty card by the Loyalty ID.'}
        </Typography>
        <div className={styles.content}>
          <LazyLoyaltyView passId={passId} title={title}/>
        </div>
        <LazyOverrideFooter
          navItems={[
            {
              route: `${LOYALTY_SHARE_ROUTE}?passId=${passId}`,
              icon: SHARE,
              message: 'Share',
            },
            { route: `${LOYALTY_ROUTE}?passId=${passId}`, icon: REWARD, message: 'Loyalty' },
            { route: `${LOYALTY_QR_ROUTE}?passId=${passId}`, icon: SCAN, message: 'QR' },
            { route: `${LOYALTY_SEARCH_ROUTE}?passId=${passId}`, icon: SEARCH, message: 'Search' },
          ]}
          currentItem="Loyalty"
          />
      </Page>
  );
};

SuspendedLoyaltyRedeemNavPage.propTypes = {
  passId: string,
  title: string,
};

export default SuspendedLoyaltyRedeemNavPage;
